/// <reference path="../../../../../node_modules/monaco-editor/monaco.d.ts" />
import { Component, OnInit } from "@angular/core";
import { BaseComponent } from "../../../shared/base.component";
import { ApiService } from "src/app/services";
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute } from "@angular/router";
import { ColumnApi, GridOptions } from "ag-grid-community";
import { extendDefaultOptions } from "src/app/utility/gridHelper";
import { CapacityOrderMetadataColumnDefinition, CapacitySubOrdersColumnDefinition } from "../ag-grid-column-definition";
import { LoadingScreenService } from "src/app/shared/loading-screen.service";
import { PreprocessDateTime } from "src/app/utility/view-field-mapping";
import { ISubOrderDetail } from "src/app/npr-request.model";
import { ModalService } from "../../../shared/modal.service";
import { CapacityOrderDetails } from "../../../generated-models/CapacityOrderDetails";
interface ICapacityOrderMetadataData {
  MetadataKey: string;
  MetadataValue: string;
}

@Component({
  templateUrl: "./capacity-order-detail.component.html",
  styleUrls: ["../../../styles.scss", "../plan.scss"],
})
export class CapacityOrderDetailComponent extends BaseComponent implements OnInit {
  capacityOrderId: string;
  serviceTeam: string;
  capacityOrderDetail: CapacityOrderDetails;

  subOrdersGridOptions: GridOptions;
  subOrdersGridColumnApi: ColumnApi;
  subOrderRowData: ISubOrderDetail[] = [];

  metadataGridOptions: GridOptions;
  metadataGridColumnApi: ColumnApi;
  metadataRowData: ICapacityOrderMetadataData[] = [];

  approvalDate: string;
  etaDate: string;
  expiryDate: string;
  needByDate: string;
  orderStatus: string;
  actionRequired: boolean;
  readonly sharedResourceName = "cores";

  constructor(
    private readonly route: ActivatedRoute,
    private apiService: ApiService,
    private modalService: ModalService,
    private loadingService: LoadingScreenService,
    private notificationService: ToastrService
  ) {
    super();
  }

  async ngOnInit() {
    await this.loadCapacityOrderDetail();
    this.loadCapacityOrderMetadata();

    this.metadataGridOptions = extendDefaultOptions({
      columnDefs: CapacityOrderMetadataColumnDefinition,
      suppressPaginationPanel: true,
      sideBar: null,
    });

    this.subOrdersGridOptions = extendDefaultOptions({
      columnDefs: CapacitySubOrdersColumnDefinition,
      enableRangeSelection: false,
      rowSelection: "single",
      animateRows: true,
      suppressPaginationPanel: true,
      sideBar: null,
    });

    this.apiService.getTeamList().subscribe((response) => {
      if (response) {
        response.map((t) => {
          if (t.Oid === this.capacityOrderDetail?.ServiceTreeId) {
            this.serviceTeam = t.Name;
          }
        });
      }
    });
  }

  async loadCapacityOrderDetail() {
    this.loadingService.setLoading(true);
    this.capacityOrderId = this.route.snapshot.params["capacityOrderId"];

    try {
      this.capacityOrderDetail = await this.apiService.refreshCapacityOrder(this.capacityOrderId).toPromise();
      this.approvalDate = PreprocessDateTime(this.capacityOrderDetail.ApprovalDate);
      this.etaDate = PreprocessDateTime(this.capacityOrderDetail.EtaDate);
      this.expiryDate = PreprocessDateTime(this.capacityOrderDetail.ExpiryDate);
      this.needByDate = PreprocessDateTime(this.capacityOrderDetail.NeedByDate);
      this.actionRequired = this.capacityOrderDetail.Status === "CommunicationNeeded";
      this.orderStatus = this.actionRequired ? "Action Required" : this.capacityOrderDetail.Status;
      this.capacityOrderDetail.Orders.forEach((order) => {
        order.Resources.forEach((resource) => {
          resource.ResourceDetails.forEach((resourceDetail) => {
            const subOrderRowData: ISubOrderDetail = {
              OrderId: this.capacityOrderId,
              ResourceProvider: order.ResourceProvider,
              SubOrderId: resourceDetail.SubOrderId ?? "Waiting Sync",
              Location: resource.Location,
              ResourceName: resourceDetail.RequestedResource.ResourceName,
              Limits: resourceDetail.RequestedResource.Limits,
              Unit: resourceDetail.RequestedResource.Unit,
              ProviderSpecificProperties: "",
              ApprovedResource: resourceDetail.ApprovedResource,
              Status: resourceDetail.Status,
              Messages: resourceDetail.Messages,
            };
            resourceDetail.RequestedResource.ProviderSpecificProperties.forEach((prop) => {
              subOrderRowData.ProviderSpecificProperties += `{${prop.Name} : ${prop.Nvalue}} `;
            });
            if (subOrderRowData.ResourceName && subOrderRowData.ResourceName !== this.sharedResourceName) {
              this.subOrderRowData.push(subOrderRowData);
            }
          });
        });
      });
    } catch (err) {
      this.capacityOrderDetail = null;
      this.notificationService.error(err);
    }

    this.loadingService.setLoading(false);

    setTimeout(() => {
      this.subOrdersGridColumnApi.autoSizeAllColumns();
      this.metadataGridColumnApi.autoSizeAllColumns();
    }, 100);
  }

  loadCapacityOrderMetadata() {
    if (this.capacityOrderDetail !== null) {
      Object.entries(this.capacityOrderDetail.Metadata).forEach((entry) => {
        const data: ICapacityOrderMetadataData = {
          MetadataKey: entry[0],
          MetadataValue: entry[1].toString(),
        };
        this.metadataRowData.push(data);
      });
    }
  }

  onSubOrdersGridReady(params: GridOptions) {
    this.subOrdersGridColumnApi = params.columnApi;
  }

  onMetadataGridReady(params: GridOptions) {
    this.metadataGridColumnApi = params.columnApi;
  }
}

/// <reference path="../../../../node_modules/monaco-editor/monaco.d.ts" />
import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { BaseComponent } from "../../shared/base.component";
import { ApiService } from "src/app/services";
import { LoadingScreenService } from "src/app/shared/loading-screen.service";
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute, Router } from "@angular/router";
import { PlanRegionDetail } from "src/app/generated-models/PlanRegionDetail";
import { PlanSubmission } from "src/app/generated-models/PlanSubmission";
import { PlanRegionUpdateData } from "src/app/generated-models/PlanRegionUpdateData";
import { PlanValidityEnum } from "src/app/shared/enums/plan-enums";
import { PreprocessDateTime } from "../../utility/view-field-mapping";
import { ColumnApi, GridApi, GridOptions } from "ag-grid-community";
import { extendDefaultOptions } from "src/app/utility/gridHelper";
import { CapacityOrderColumnDefinition } from "./ag-grid-column-definition";
import { CapacityOrder } from "src/app/generated-models/CapacityOrder";
import { ModalService } from "src/app/shared/modal.service";
import { CapacityOrderRefreshResult } from "src/app/generated-models/CapacityOrderRefreshResult";
import { Region } from "src/app/generated-models";
@Component({
  templateUrl: "./plan-detail.component.html",
  styleUrls: ["../../styles.scss", "./plan.scss"],
})
export class PlanDetailComponent extends BaseComponent implements OnInit {
  public gridApi: GridApi;

  editor: monaco.editor.IStandaloneCodeEditor;
  planRegion: PlanRegionDetail;
  region: Region;
  serviceTreeId: string;
  regionName: string;
  planVersion: number;
  latestVersion: number = null;
  planLatestVersionDetailLink: string;
  stage: string;
  message: string;
  planFileName: string;
  code: string;
  showRequeueButton: boolean;
  reprocessTooltip: string;
  refreshTooltip: string;
  isAdmin = false;
  oldValidToDate: Date | null = null;
  oldValidTo: string | null = null;
  isError = false;
  showCapacityOrders = true;
  requireValidToDate = false;
  processedValidToDate: string = null;
  processedNeedByDate: string = null;
  gridOptions: GridOptions;
  capacityOrderRowData: CapacityOrder[] = [];
  capacityOrderGridColumnApi: ColumnApi;
  actionRequired = false;
  readonly planValidityEnumMap = Object.keys(PlanValidityEnum).filter(key => isNaN(Number(key)));

  constructor(
    private router: Router,
    private readonly route: ActivatedRoute,
    private apiService: ApiService,
    private modalService: ModalService,
    private loadingService: LoadingScreenService,
    private notificationService: ToastrService,
    private title: Title
  ) {
    super();
    this.showRequeueButton = false;
  }

  async ngOnInit() {
    this.loadingService.setLoading(true);
    this.gridOptions = extendDefaultOptions({
      columnDefs: CapacityOrderColumnDefinition,
      enableRangeSelection: false,
      rowSelection: "single",
      animateRows: true,
      suppressPaginationPanel: true,
      sideBar: null,
    });

    await this.loadPlanDetail();

    if (this.planRegion) {
      const userProfile = await this.apiService.getUserProfile();
      if (userProfile?.IsAdmin) {
        this.isAdmin = true;
      }

      if (this.planRegion.PlanFile) {
        this.planFileName = this.planRegion.PlanFile.FileName;
        this.code = this.planRegion.PlanFile.Content;
        this.showRequeueButton = this.IsShowRequeueButtonEnabled();
        if (this.showRequeueButton) {
          this.reprocessTooltip = "Re-process the plan file if the status is still Created for a long time.";
          this.refreshTooltip = "Refresh the plan region detail as well as the related capacity orders.";
        }
      } else {
        this.router.navigate(["404"], { skipLocationChange: true });
      }

      this.planRegion.ValidToDate = this.planRegion.ValidToDate && new Date(this.planRegion.ValidToDate);
      this.oldValidToDate = this.planRegion.ValidToDate;
      this.oldValidTo = this.planRegion.ValidTo;
      this.processedValidToDate = PreprocessDateTime(this.planRegion.ValidToDate);
      this.processedNeedByDate = PreprocessDateTime(this.planRegion.NeedByDate);

      await this.loadPlanRelatedCapacityOrders();
      this.loadingService.setLoading(false);

      setTimeout(() => {
        this.capacityOrderGridColumnApi.autoSizeAllColumns();
      }, 100);
    }
  }

  onEditorReady(editor: monaco.editor.IStandaloneCodeEditor) {
    this.editor = editor;
    this.editor.getAction("editor.action.formatDocument").run();
  }

  async loadPlanDetail() {
    this.serviceTreeId = this.route.snapshot.params["serviceTreeId"];
    this.regionName = this.route.snapshot.params["region"];
    this.planVersion = this.route.snapshot.params["version"];
    this.stage = this.route.snapshot.params["stage"];
    this.title.setTitle(`${this.route.snapshot.data.title} for ${this.serviceTreeId} on ${this.regionName} v${this.planVersion}`);
    try {
      this.planRegion = await this.apiService
        .getPlanRegionDetail(this.serviceTreeId, this.regionName, this.planVersion, this.stage)
        .toPromise();
      var regions = await this.apiService.getRegionList().toPromise();
      this.actionRequired = this.planRegion.Status == "Action Required";
      this.region = regions.find((r) => r.RegionName == this.regionName);
      this.apiService
        .getPlanRegionLatestVersion(this.planRegion.ServiceTreeId, this.planRegion.Region, this.planRegion.Stage)
        .subscribe((response) => {
          this.latestVersion = response.Version;
          if (this.latestVersion != null) {
            this.planLatestVersionDetailLink = `/quota/plans/services/${this.serviceTreeId}/regions/${this.regionName}/versions/${this.latestVersion}/stages/${this.stage}/detail`;
          }
        });
    } catch (err) {
      this.planRegion = null;
      this.notificationService.error(err);
    }
  }

  async loadPlanRelatedCapacityOrders() {
    this.capacityOrderRowData = await this.apiService
      .getRegionalPlanRelatedCapacityOrders(this.serviceTreeId, this.regionName, this.planVersion, this.stage)
      .toPromise();
    if (this.capacityOrderRowData.length == 0) {
      this.showCapacityOrders = false;
    }
  }

  async requeue() {
    this.loadingService.setLoading(true);
    this.isError = false;
    const payload: PlanSubmission = {
      ServiceTreeId: this.planRegion.ServiceTreeId,
      Version: this.planRegion.PlanVersion,
      Region: this.planRegion.Region,
      Stage: this.planRegion.Stage,
    } as PlanSubmission;

    if (this.latestVersion == this.planRegion.PlanVersion) {
      this.apiService.requeuePlanRegion(payload).subscribe(
        () => {
          this.message = `Plan file will be processed soon.`;
          this.notificationService.info(this.message);
          this.showRequeueButton = false;
        },
        () => {
          this.message = `Re-process plan file failed.`;
          this.notificationService.error(this.message);
          this.isError = true;
        },
        async () => {
          this.loadingService.setLoading(false);
        }
      );
    } else {
      this.loadingService.setLoading(false);
      this.modalService.planReprocessModal(payload, this.latestVersion).then((r) => {
        if (r === "success") {
          this.showRequeueButton = false;
        } else if (r === "error") {
          this.isError = true;
        }
      });
    }
  }

  async refresh() {
    this.loadingService.setLoading(true);
    this.apiService.refreshCapacityOrdersByPlanRegionAsync(this.serviceTreeId, this.regionName, this.planVersion, this.stage).subscribe(
      (response: CapacityOrderRefreshResult[]) => {
        if (response != null) {
          response.forEach((order: CapacityOrderRefreshResult) => {
            console.log(order);
            this.capacityOrderRowData.forEach((data) => {
              if (data.CapacityOrderId === order.CapacityOrderId) {
                data.Status = order.Status;
              }
            });
          });
        }

        this.loadingService.setLoading(false);
        this.notificationService.info(`Refresh plan region successfully!`);
        this.gridApi?.refreshCells();
      },
      (e: unknown) => {
        this.loadingService.setLoading(false);
        this.notificationService.error(e as string);
      }
    );

    try {
      this.planRegion = await this.apiService
        .getPlanRegionDetail(this.serviceTreeId, this.regionName, this.planVersion, this.stage)
        .toPromise();
    } catch (err) {
      this.planRegion = null;
      this.notificationService.error(err);
    }
  }

  IsUpdateButtonDisabled(): boolean {
    return this.isError || (!this.ValidToDateUpdated() && !this.ValidToUpdated());
  }

  ValidToDateUpdated(): boolean {
    return this.oldValidToDate?.getTime() !== this.planRegion.ValidToDate?.getTime();
  }

  ValidToUpdated(): boolean {
    return this.oldValidTo !== this.planRegion.ValidTo;
  }

  onCapacityOrderGridReady(params: GridOptions) {
    this.capacityOrderGridColumnApi = params.columnApi;
  }

  IsShowRequeueButtonEnabled(): boolean {
    return this.isAdmin;
  }

  SubmitChanges(): void {
    if (this.ValidToDateUpdated() || this.ValidToUpdated()) {
      const updateData = {
        ValidToDate: this.planRegion.ValidToDate,
        ValidTo: this.planRegion.ValidTo,
      } as PlanRegionUpdateData;
      this.loadingService.setLoading(true);
      this.apiService
        .updateValidToDate(this.planRegion.ServiceTreeId, this.planRegion.Region, this.planVersion, this.planRegion.Stage, updateData)
        .subscribe(
          () => {
            const msg = `Successfully updated valid to date`;
            this.oldValidToDate = this.planRegion.ValidToDate;
            this.oldValidTo = this.planRegion.ValidTo;
            this.notificationService.info(msg);
            this.loadingService.setLoading(false);
          },
          (error: unknown) => {
            const message = `Failed to update valid to date, error: ${error}.`;
            this.notificationService.error(message);
            this.loadingService.setLoading(false);
          }
        );
    }
  }

  unBlockAction() {
    this.capacityOrderRowData.forEach((order) => {
      var currentOrderUrl = `/quota/plans/capacityOrder/${order.CapacityOrderId}`;
      window.open(currentOrderUrl, "_blank");
    });
  }

  GetValidTo(item: any) {
    if (item === "GA") {
      return "MA";
    }
    return item;
  }
}

<h4>Request Details</h4>
<ul class="panel-container">
  <!--Arm Access with no quota -->
  <li>
    <label>
      <input type="checkbox"
             [(ngModel)]="detailsInfo.enableArmWithNoQuota"
             name="ArmNoQuota"
             data-test="ArmNoQuota" />
      ARM Access Without VM Quota
    </label>
    <div class="ml-2 popover-container">
      <i class="fa fa-info-circle" placement="right" [ngbPopover]="ArmNoQuota"> </i>
      <ng-template #ArmNoQuota>
        <span>ARM access **ONLY** with VM quota set to 0. This is exclusive with all other request types except AZ Access and always gets processed automatically with no manual intervention.</span>
      </ng-template>
    </div>
  </li>

  <!--AZ enablement-->
  <li>
    <label>
      <input
        [disabled]="serviceDisabled.AZ"
        type="checkbox"
        [(ngModel)]="detailsInfo.azEnablement"
        name="AzEnablement"
        data-test="AzEnablement"
      />
      Availability Zone (AZ) Access
    </label>
    <div *ngIf="detailsInfo.azEnablement">
        <ul class="list-container">
            <li class="flex-box mb-3">
                <div class="col-md-3">Zones</div>
                <label class="col-md-9">
                    <ng-select
                        [(ngModel)]="detailsInfo.zones"
                        [items]="detailsInfo.azEntities"
                        [multiple]="true"
                        [closeOnSelect]="false"
                        name="azEntities"
                        placeholder="Select zone(s) you want to access"
                        required
                        data-test="AzEntities"
                        #azEntities="ngModel">
                    </ng-select>
                    <em *ngIf="isTouchedOrDirty(azEntities) && detailsInfo.zones.length === 0">Required</em>
                </label>
            </li>
        </ul>
    </div>
    <div *ngIf="serviceDisabled.AZ" class="ml-2 popover-container">
      <i class="fa fa-info-circle" placement="right" [ngbPopover]="AZPopOver"> </i>
      <ng-template #AZPopOver>
        <span>There is no AZ on this region or all zones are accessible to all subscriptions.</span>
      </ng-template>
    </div>
  </li>

  <ng-container *ngIf="!detailsInfo.enableArmWithNoQuota">
      <!--Region enablement-->
      <li>
          <label>
              <input [disabled]="serviceDisabled.RegionEnable"
                     type="checkbox"
                     [(ngModel)]="detailsInfo.regionEnablement"
                     name="RegionEnablement"
                     data-test="RegionEnablement" />
              Region Access
          </label>
          <div *ngIf="serviceDisabled.RegionEnable" class="ml-2 popover-container">
              <i class="fa fa-info-circle" placement="right" [ngbPopover]="RegionEnablementNotSupportedPopOver"> </i>
              <ng-template #RegionEnablementNotSupportedPopOver>
                  <span>Region access request is disbaled for the region.</span>
              </ng-template>
          </div>

          <ul class="list-container pl-3" *ngIf="detailsInfo.regionEnablement && !serviceDisabled.RegionEnable">
              <li class="flex-box">
                  <label>
                      <input [disabled]="serviceDisabled.ARM"
                             type="checkbox"
                             [(ngModel)]="detailsInfo.regionEnablementARM"
                             name="RegionEnablementARM"
                             title="RegionEnablementARM"
                             data-test="RegionEnablementARM" />
                      ARM
                      <label *ngIf="serviceDisabled.ARM" class="ml-2 popover-container">
                          <i class="fa fa-info-circle" placement="right" [ngbPopover]="ArmAccessPopOver"> </i>
                          <ng-template #ArmAccessPopOver>
                              <span>'{{ region.label }}' is not a managed region. ARM region access requests are not necessary.</span>
                          </ng-template>
                      </label>
                  </label>
              </li>
              <li class="flex-box">
                  <label>
                      <input [disabled]="serviceDisabled.RDFE"
                             type="checkbox"
                             [(ngModel)]="detailsInfo.regionEnablementRDFE"
                             name="RegionEnablementRDFE"
                             title="RegionEnablementRDFE"
                             data-test="RegionEnablementRDFE" />
                      RDFE
                  </label>
              </li>
          </ul>
      </li>

      <!--Compute ARM VM quota-->
      <li>
          <label>
              <input [disabled]="serviceDisabled.ArmVmQuota"
                     type="checkbox"
                     [(ngModel)]="detailsInfo.requireComputeArmVmQuota"
                     name="RequireComputeArmVmQuota"
                     data-test="RequireComputeArmVmQuota" />
              Compute VM ARM SKU Access and Quota
          </label>
          <!--VM quota list for ARM-->
          <div>
              <ul class="list-container" *ngIf="detailsInfo.requireComputeArmVmQuota && !serviceDisabled.ArmVmQuota">
                  <li class="flex-box">
                      <div class="col-md-3">SKU(s)</div>
                      <label class="col-md-9">
                          <ng-select [(ngModel)]="detailsInfo.vmQuota"
                                     [items]="service.vmSkuList"
                                     [multiple]="true"
                                     name="VMQuota"
                                     groupBy="skuType"
                                     required
                                     data-test="VMQuota"
                                     #vmQuota="ngModel">
                              <ng-template ng-option-tmp let-item="item">
                                  <ng-container *ngIf="item.disabled">
                                      <label title="{{ item.label }} is not supported in the current region">{{ item.label }}</label>
                                  </ng-container>
                                  <ng-container *ngIf="!item.disabled">
                                      <label title="{{ item.label }}" attr.data-test="{{ item.label }}">
                                          {{ item.label }}
                                      </label>
                                  </ng-container>
                              </ng-template>
                          </ng-select>
                          <em *ngIf="isTouchedOrDirty(vmQuota) && !isVMSkuSelected()">Required</em>
                      </label>
                  </li>
                  <li *ngFor="let quota of detailsInfo.vmQuota" class="flex-box mt-1 border-bottom">
                      <div class="quota-key col-md-3">{{ quota.label }}</div>
                      <div class="col-md-9">
                        <div>
                            <label class="quota-checked">
                                <input
                                    type="checkbox"
                                    [(ngModel)]="quota.checked"
                                    [ngModelOptions]="{ standalone: true }"
                                    attr.data-test="VMChecked-{{ quota.label }}" />
                                Increase quota limit
                            </label>
                            <input
                                *ngIf="quota.checked"
                                class="ml-3 quota-value"
                                type="number"
                                [(ngModel)]="quota.quota"
                                [ngModelOptions]="{ standalone: true }"
                                placeholder="New quota limit"
                                attr.data-test="Input-{{ quota.label }}"
                                #deploymentScope="ngModel" />
                        </div>
                        <div *ngIf="detailsInfo.deploymentScope === 'Zonal'">
                            <ng-select
                                [items]="detailsInfo.azEntities"
                                [multiple]="true"
                                [(ngModel)]="quota.zones"
                                [closeOnSelect]="false"
                                [selectableGroup]="true"
                                placeholder="Select Zone(s), Leave empty to request access to all zones">
                                <ng-template ng-option-tmp let-item="item">Physical Zone: {{ item.substring(item.lastIndexOf('-') + 1) }}</ng-template>
                            </ng-select>
                        </div>
                      </div>
                  </li>
                  <em *ngIf="!isVmQuotaValid">New quota limit is required and must be greater than 0</em>
                  <!-- For deploymentScope field -->
                  <li *ngIf="isDeploymentScopeEnabled" class="flex-box mb-3 mt-3">
                      <div class="col-md-3">
                          Deployment Scope
                          <div class="ml-2 popover-container">
                              <i class="fa fa-info-circle" placement="right" [ngbPopover]="DeploymentScopePopOver"> </i>
                              <ng-template #DeploymentScopePopOver>
                                  <span>Choose 'Regional' or 'Zonal' to indicate whether it's a CAS or zonal CAS request.</span>
                              </ng-template>
                          </div>
                      </div>
                      <label class="col-md-9">
                          <ng-select [(ngModel)]="detailsInfo.deploymentScope"
                                     name="DeploymentScope"
                                     [items]="deploymentScopeList"
                                     required
                                     #deploymentScope="ngModel">
                          </ng-select>
                          <em *ngIf="isTouchedOrDirty(deploymentScope) && !detailsInfo.deploymentScope">Required</em>
                      </label>
                  </li>
              </ul>
          </div>
      </li>

      <!--Compute ARM shared VM Quota-->
      <li>
          <label>
              <input [disabled]="serviceDisabled.ArmVmRegionalQuota"
                     type="checkbox"
                     [(ngModel)]="detailsInfo.requireComputeArmSharedQuota"
                     name="RequireComputeArmSharedQuota"
                     data-test="RequireComputeArmSharedQuota" />
              Region Quota
          </label>
          <div>
              <ul *ngIf="detailsInfo.requireComputeArmSharedQuota && !serviceDisabled.ArmVmRegionalQuota" class="list-container">
                  <li class="flex-box mb-3">
                      <div class="col-md-3 required">Total Regional Limit</div>
                      <div class="col-md-9">
                          <input class="form-control"
                                 [(ngModel)]="detailsInfo.computeArmSharedQuota"
                                 name="ComputeArmSharedQuota"
                                 type="number"
                                 placeholder="new limit"
                                 required
                                 #computeArmSharedQuota="ngModel"
                                 data-test="Input" />
                          <ng-container *ngIf="isTouchedOrDirty(computeArmSharedQuota)">
                              <em *ngIf="!isQuotaValid(detailsInfo.computeArmSharedQuota)">New limit is required and must be greater than 0</em>
                          </ng-container>
                          <div class="info-box">
                              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                              <span>
                                  Click
                                  <a href="https://docs.microsoft.com/en-us/azure/azure-portal/supportability/regional-quota-requests#request-a-quota-increase-by-region-from-subscriptions"
                                     target="_blank">here</a>
                                  for more details
                              </span>
                          </div>
                      </div>
                  </li>
              </ul>
          </div>
      </li>

      <!--Compute ARM Low Priority Quota-->
      <li>
          <label>
              <input [disabled]="serviceDisabled.ArmLowPriorityQuota"
                     type="checkbox"
                     [(ngModel)]="detailsInfo.requireComputeArmLowPriorityQuota"
                     name="RequireComputeArmLowPriorityQuota"
                     data-test="RequireComputeArmLowPriorityQuota" />
              Compute ARM Low Priority Quota
          </label>
          <div>
              <ul *ngIf="detailsInfo.requireComputeArmLowPriorityQuota && !serviceDisabled.ArmLowPriorityQuota" class="list-container">
                  <li class="flex-box mb-3">
                      <div class="col-md-3 required">Total Low Priority Limit</div>
                      <div class="col-md-9">
                          <input class="form-control"
                                 [(ngModel)]="detailsInfo.computeArmLowPriorityQuota"
                                 name="ComputeArmLowPriorityQuota"
                                 type="number"
                                 placeholder="new limit"
                                 required
                                 #computeArmLowPriorityQuota="ngModel"
                                 data-test="Input" />
                          <ng-container *ngIf="isTouchedOrDirty(computeArmLowPriorityQuota)">
                              <em *ngIf="!isQuotaValid(detailsInfo.computeArmLowPriorityQuota)">New limit is required and must be greater than 0</em>
                          </ng-container>
                      </div>
                  </li>
              </ul>
          </div>
      </li>

      <!--Compute RDFE Quota-->
      <li>
          <label>
              <input [disabled]="serviceDisabled.RdfeVmQuota"
                     type="checkbox"
                     [(ngModel)]="detailsInfo.requireComputeRdfeVmQuota"
                     name="RequireComputeRdfeVmQuota"
                     data-test="RequireComputeRdfeVmQuota" />
              Compute RDFE Quota
          </label>
          <label *ngIf="detailsInfo.requireComputeRdfeVmQuota && !serviceDisabled.RdfeVmQuota" class="ml-2 popover-container">
              <i class="fa fa-info-circle" placement="right" [ngbPopover]="ComputeRdfeVmQuotaPopover"> </i>
              <ng-template #ComputeRdfeVmQuotaPopover>
                  <span>
                      Note that RDFE quota is region agnostic and SKU agnostic. When reusing subscription from different region/deployment, add the
                      <a target="_blank"
                         href="https://nam06.safelinks.protection.outlook.com/?url=https:%2F%2Fdocs.microsoft.com%2Fen-us%2Fazure%2Fnetworking%2Fcheck-usage-against-limits%23:~:text%3DAzure%2520Portal.%25201%2520Log%2520into%2520the%2520Azure%2520portal.%2Cor%2520select%2520Compute%252C%2520Network%252C%2520or%2520Storage.%2520More%2520items&data=04%7C01%7CXiaohang.Lou%40microsoft.com%7C5e66f920ce9243f6127f08d87661358d%7C72f988bf86f141af91ab2d7cd011db47%7C1%7C0%7C637389507196457568%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C1000&sdata=Luz%2BFGKWw5tBXEzhHFjXGNQYoz%2FiwjjVV35fz2VQKlM%3D&reserved=0">
                          current limit
                      </a>
                      + desired new quantity and provide the total quota required.
                  </span>
              </ng-template>
          </label>
          <ng-container *ngIf="detailsInfo.requireComputeRdfeVmQuota && !serviceDisabled.RdfeVmQuota">
              <em *ngIf="isExternal">Compute RDFE quota request is only for internal teams</em>
          </ng-container>
          <!--RDFE Quota Input-->
          <div *ngIf="detailsInfo.requireComputeRdfeVmQuota && !serviceDisabled.RdfeVmQuota">
              <ul class="list-container">
                  <li class="flex-box mb-3">
                      <div class="col-md-3 required">New total quota across all regions</div>
                      <div class="col-md-9">
                          <input class="form-control"
                                 [(ngModel)]="detailsInfo.computeRdfeVmQuota"
                                 name="ComputeRdfeVmQuota"
                                 type="number"
                                 placeholder="new limit"
                                 required
                                 #computeRdfeVmQuota="ngModel"
                                 data-test="Input" />
                          <ng-container *ngIf="isTouchedOrDirty(computeRdfeVmQuota)">
                              <em *ngIf="!isQuotaValid(detailsInfo.computeRdfeVmQuota)">Quota is required and must be greater than 0</em>
                          </ng-container>
                      </div>
                  </li>
                  <li class="flex-box mb-3">
                      <div class="col-md-3 required">Primary SKU (family) you plan to use</div>
                      <label class="col-md-9">
                          <ng-select [(ngModel)]="detailsInfo.rdfeVmSku"
                                     [items]="rdfeSkuList"
                                     name="RdfeVmSku"
                                     required
                                     #rdfeVmSku="ngModel"
                                     data-test="RdfeVmSku">
                              <ng-template ng-option-tmp let-item="item">
                                  <ng-container *ngIf="item.disabled">
                                      <label title="{{ item.label }} is not supported in the current region">{{ item.label }}</label>
                                  </ng-container>
                                  <ng-container *ngIf="!item.disabled">
                                      <label title="{{ item.label }}" attr.data-test="{{ item.label }}">
                                          {{ item.label }}
                                      </label>
                                  </ng-container>
                              </ng-template>
                          </ng-select>
                          <ng-container *ngIf="isTouchedOrDirty(rdfeVmSku)">
                              <em *ngIf="rdfeVmSku.errors?.required">Required</em>
                          </ng-container>
                      </label>
                  </li>
              </ul>
          </div>
      </li>

      <!--Storage Quota enablement-->
      <li>
          <label>
              <input [disabled]="serviceDisabled.StorageQuota"
                     type="checkbox"
                     [(ngModel)]="detailsInfo.additionalStorage"
                     name="AdditionalStorage"
                     data-test="AdditionalStorage" />
              Storage Account Quota
          </label>
          <!--Storage Quota-->
          <div *ngIf="detailsInfo.additionalStorage && !serviceDisabled.StorageQuota">
              <ul class="list-container">
                  <li class="flex-box mb-3">
                      <div class="col-md-3">ARM</div>
                      <div class="col-md-9">
                          <input [disabled]="serviceDisabled.ArmStorageQuota"
                                 class="form-control"
                                 [(ngModel)]="detailsInfo.storageQuotaARM"
                                 name="StorageQuotaARM"
                                 type="number"
                                 placeholder="new limit"
                                 required
                                 #storageQuotaARM="ngModel"
                                 data-test="ArmStorageQuota" />
                          <div class="info-box">
                              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                              <span>ARM storage quota should be in range of {{ armStorageLimits[0] }}-{{ armStorageLimits[1] }} </span>
                          </div>
                          <ng-container *ngIf="isTouchedOrDirty(storageQuotaARM)">
                              <em *ngIf="detailsInfo.storageQuotaARM && !isStorageQuotaArmValid">ARM storage quota is required and should be in range of {{ armStorageLimits[0] }}-{{ armStorageLimits[1] }}</em>
                          </ng-container>
                      </div>
                  </li>
                  <li class="flex-box mb-3">
                      <div class="col-md-3">RDFE</div>
                      <div class="col-md-9">
                          <input [disabled]="serviceDisabled.RdfeStorageQuota"
                                 class="form-control"
                                 [(ngModel)]="detailsInfo.storageQuotaRDFE"
                                 name="StorageQuotaRDFE"
                                 type="number"
                                 placeholder="new limit"
                                 required
                                 #storageQuotaRDFE="ngModel"
                                 data-test="RdfeStorageQuota" />
                          <div class="info-box">
                              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                              <span>RDFE storage quota should be in range of {{ rdfeStorageLimits[0] }}-{{ rdfeStorageLimits[1] }}</span>
                          </div>
                          <ng-container *ngIf="isTouchedOrDirty(storageQuotaRDFE)">
                              <em *ngIf="detailsInfo.storageQuotaRDFE && !isStorageQuotaRdfeValid">RDFE storage quota is required and should be in range of {{ rdfeStorageLimits[0] }}-{{ rdfeStorageLimits[1] }}</em>
                          </ng-container>
                      </div>
                  </li>
              </ul>
          </div>
      </li>

      <!--SQL enablement-->
      <li>
          <label>
              <input [disabled]="serviceDisabled.SQL" type="checkbox" [(ngModel)]="detailsInfo.sql" name="Sql" data-test="Sql" />
              SQL Access
          </label>
          <ul class="list-container" *ngIf="detailsInfo.sql && !serviceDisabled.SQL && isRegionLive">
              <li class="flex-box mb-3">
                  <div class="col-md-3 required">Purchase Model</div>
                  <div class="col-md-9">
                      <label class="mr-3">
                          <input class="mr-1"
                                 type="radio"
                                 name="PurchaseModel"
                                 value="DTUs"
                                 [(ngModel)]="detailsInfo.sqlPurchaseModel"
                                 data-test="DTUs" />
                          <span>{{ sqlPurchaseModelType.DTUs }}</span>
                      </label>
                      <label>
                          <input class="mr-1"
                                 type="radio"
                                 name="PurchaseModel"
                                 value="vCores"
                                 [(ngModel)]="detailsInfo.sqlPurchaseModel"
                                 data-test="VCores" />
                          <span>{{ sqlPurchaseModelType.VCores }}</span>
                      </label>
                      <div class="info-box">
                          <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                          <span>Approved requests will enable creation of both DTU and vCore databases </span>
                      </div>
                  </div>
              </li>
              <li class="flex-box mb-3">
                  <div class="col-md-3 required">Expected Consumption</div>
                  <div class="col-md-9">
                      <input class="form-control"
                             [(ngModel)]="detailsInfo.sqlQuota"
                             name="SqlQuota"
                             type="number"
                             required
                             placeholder="Enter the number of vCores or DTUs."
                             #sqlQuota="ngModel"
                             data-test="SqlQuota" />
                      <em *ngIf="isTouchedOrDirty(sqlQuota) && !isQuotaValid(detailsInfo.sqlQuota)">Quota is required and must be greater than 0</em>
                      <div class="info-box">
                          <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                          <span>
                              Business Critical(BC) service tier requires four (4) times more vCore capacity than General Purpose (GP) service tier. For
                              example: 1 GP vCore = 1 vCore unit and 1 BC vCore = 4 vCore units.
                          </span>
                      </div>
                  </div>
              </li>
          </ul>
      </li>

      <!--SQL MI enablement-->
      <li>
          <label>
              <input type="checkbox" [disabled]="serviceDisabled.SQLMI" [(ngModel)]="detailsInfo.sqlMI" name="SqlMI" data-test="SQLMI" />
              SQL MI Access and Quota
          </label>
          <!--SQL MI vCores and DB Count -->
          <ul *ngIf="detailsInfo.sqlMI && !serviceDisabled.SQLMI" class="list-container">
              <li>
                  <label>
                      <input type="radio" [value]="sqlMiType.Default" [(ngModel)]="detailsInfo.sqlMiType" name="sqlMiType" />
                      <span class="ml-1">{{ sqlMiType.Default }}</span>
                  </label>
                  <label class="ml-3">
                      <input type="radio" [value]="sqlMiType.TotalvCores" [(ngModel)]="detailsInfo.sqlMiType" name="sqlMiType" />
                      <span class="ml-1">{{ sqlMiType.TotalvCores }}</span>
                  </label>
                  <label class="ml-3">
                      <input type="radio" [value]="sqlMiType.DetailInstances" [(ngModel)]="detailsInfo.sqlMiType" name="sqlMiType" />
                      <span class="ml-1">{{ sqlMiType.DetailInstances }}</span>
                  </label>
              </li>
              <li>
                  <ul *ngIf="sqlMiTypeEqPDI">
                      <li class="flex-box mb-3">
                          <div class="col-md-3 required">General Purpose vCores</div>
                          <div class="col-md-9">
                              <ng-select [(ngModel)]="detailsInfo.sqlMiGPvCores"
                                         name="SqlMiGPvCores"
                                         [items]="sqlMiVcoresList"
                                         required
                                         #sqlMiGPvCores="ngModel">
                              </ng-select>
                              <em *ngIf="isTouchedOrDirty(sqlMiGPvCores) && !isListItemValid(detailsInfo.sqlMiGPvCores, sqlMiVcoresList)">Required</em>
                          </div>
                      </li>
                      <li class="flex-box mb-3">
                          <div class="col-md-3 required">Number of General Purpose instance</div>
                          <div class="col-md-9">
                              <input class="form-control"
                                     [(ngModel)]="detailsInfo.sqlMiGPCount"
                                     type="number"
                                     name="SqlMiGPCount"
                                     required
                                     #sqlMiGPCount="ngModel" />
                              <em *ngIf="isTouchedOrDirty(sqlMiGPCount) && !isQuotaValid(detailsInfo.sqlMiGPCount)">Instance number is required and must be greater than 0</em>
                          </div>
                      </li>
                      <li class="flex-box mb-3">
                          <div class="col-md-3 required">Business Critical vCores</div>
                          <div class="col-md-9">
                              <ng-select [(ngModel)]="detailsInfo.sqlMiBCvCores"
                                         name="SqlMiBCvCores"
                                         [items]="sqlMiVcoresList"
                                         required
                                         #sqlMiBCvCores="ngModel">
                              </ng-select>
                              <em *ngIf="isTouchedOrDirty(sqlMiBCvCores) && !isListItemValid(detailsInfo.sqlMiBCvCores, sqlMiVcoresList)">Required</em>
                          </div>
                      </li>
                      <li class="flex-box mb-3">
                          <div class="col-md-3 required">Number of Business Critical instance</div>
                          <div class="col-md-9">
                              <input class="form-control"
                                     [(ngModel)]="detailsInfo.sqlMiBCCount"
                                     type="number"
                                     name="SqlMiBCCount"
                                     required
                                     #sqlMiBCCount="ngModel" />
                              <em *ngIf="isTouchedOrDirty(sqlMiBCCount) && !isQuotaValid(detailsInfo.sqlMiBCCount)">Instance number must be greater than 0</em>
                          </div>
                      </li>
                      <li class="flex-box mb-3">
                          <div class="col-md-3 required">SubNet Quota</div>
                          <div class="col-md-9">
                              <ng-select [(ngModel)]="detailsInfo.sqlMiSubNet" name="SqlMiSubNet" [items]="sqlMiSubNetList" required #sqlMiSubNet="ngModel">
                              </ng-select>
                              <em *ngIf="isTouchedOrDirty(sqlMiSubNet) && !isListItemValid(detailsInfo.sqlMiSubNet, sqlMiSubNetList)">Required</em>
                          </div>
                      </li>
                  </ul>
                  <ul *ngIf="sqlMiTypeEqPTC">
                      <li class="flex-box mb-3">
                          <div class="col-md-3 required">Total vCores</div>
                          <div class="col-md-9">
                              <input class="form-control"
                                     [(ngModel)]="detailsInfo.sqlMiTotalvCores"
                                     type="number"
                                     name="SqlMiTotalvCores"
                                     required
                                     #sqlMiTotalvCores="ngModel" />
                              <em *ngIf="isTouchedOrDirty(sqlMiTotalvCores) && !isQuotaValid(detailsInfo.sqlMiTotalvCores)">Total vCores should be more than 0</em>
                          </div>
                      </li>
                      <li class="flex-box mb-3">
                          <div class="col-md-3 required">SubNet Quota</div>
                          <div class="col-md-9">
                              <ng-select [(ngModel)]="detailsInfo.sqlMiSubNet" name="SqlMiSubNet" [items]="sqlMiSubNetList" required #sqlMiSubNet="ngModel">
                              </ng-select>
                              <em *ngIf="isTouchedOrDirty(sqlMiSubNet) && !isListItemValid(detailsInfo.sqlMiSubNet, sqlMiSubNetList)">Required</em>
                          </div>
                      </li>
                  </ul>
              </li>
          </ul>
      </li>

      <!-- HDI quota -->
      <li>
          <label>
              <input type="checkbox" [disabled]="serviceDisabled.HDInsight" [(ngModel)]="detailsInfo.hdi" name="Hdi" data-test="Hdi" />
              HD Insights Quota
          </label>
          <ul class="list-container" *ngIf="detailsInfo.hdi && !serviceDisabled.HDInsight">
              <li class="flex-box mb-3">
                  <div class="col-md-3 required">New Limit</div>
                  <div class="col-md-9">
                      <input class="form-control"
                             [(ngModel)]="detailsInfo.hdiQuota"
                             name="HdiQuota"
                             title="HdiQuota"
                             type="number"
                             required
                             #hdiQuota="ngModel"
                             data-test="HdiQuota" />
                      <em *ngIf="isTouchedOrDirty(hdiQuota) && !isQuotaValid(detailsInfo.hdiQuota)">Quota is required and must be greater than 0</em>
                  </div>
              </li>
              <li class="flex-box mb-3">
                  <div class="col-md-3 required">Primary SKU (family) you plan to use</div>
                  <label class="col-md-9">
                      <ng-select [(ngModel)]="detailsInfo.hdiSku" name="HdiSku" [items]="hdiSkuList" required #hdiSku="ngModel" data-test="HdiSku">
                          <ng-template ng-option-tmp let-item="item">
                              <ng-container>
                                  <label attr.data-test="{{ item.label }}">
                                      {{ item.label }}
                                  </label>
                              </ng-container>
                          </ng-template>
                      </ng-select>
                      <em *ngIf="isTouchedOrDirty(hdiSku) && !isHdiSkuValid">Required</em>
                  </label>
              </li>
          </ul>
      </li>

      <!-- Batch total accounts -->
      <li>
          <label>
              <input type="checkbox"
                     [disabled]="serviceDisabled.BatchQuota"
                     [(ngModel)]="detailsInfo.requireBatchAccounts"
                     name="RequireBatchAccounts"
                     data-test="RequireBatchAccounts" />
              Batch Accounts Increase Per Subscription
          </label>
          <ul class="list-container" *ngIf="detailsInfo.requireBatchAccounts && !serviceDisabled.BatchQuota">
              <li class="flex-box mb-3">
                  <div class="col-md-3 required">New Limit</div>
                  <div class="col-md-9">
                      <input class="form-control"
                             [(ngModel)]="detailsInfo.batchTotalAccounts"
                             name="BatchTotalAccounts"
                             title="BatchTotalAccounts"
                             type="number"
                             #batchTotalAccounts="ngModel"
                             required
                             data-test="BatchTotalAccounts" />
                      <em *ngIf="isTouchedOrDirty(batchTotalAccounts) && !isBatchAccountQuotaValid">New limit is required and must be greater than 0</em>
                  </div>
              </li>
          </ul>
      </li>

      <!-- Batch compute quota -->
      <li>
          <label>
              <input type="checkbox"
                     [disabled]="serviceDisabled.BatchQuota"
                     [(ngModel)]="detailsInfo.requireBatchComputeQuota"
                     name="RequireBatchComputeQuota"
                     data-test="RequireBatchComputeQuota" />
              Batch Quota Increase Per Account
          </label>
          <ul class="list-container" *ngIf="detailsInfo.requireBatchComputeQuota && !serviceDisabled.BatchQuota">
              <li class="flex-box mb-3">
                  <div class="col-md-3 required">Batch Account Name</div>
                  <div class="col-md-9">
                      <input class="form-control"
                             [(ngModel)]="detailsInfo.batchAccountName"
                             name="BatchAccountName"
                             title="BatchAccountName"
                             type="text"
                             required
                             #batchAccountName="ngModel"
                             [pattern]="batchAccountNameRegExpression"
                             data-test="BatchAccountName" />
                      <ng-container *ngIf="isTouchedOrDirty(batchAccountName)">
                          <em *ngIf="batchAccountName.errors?.required">Required</em>
                          <em *ngIf="batchAccountName.errors?.pattern">Please enter valid Batch Account Name.</em>
                      </ng-container>
                  </div>
              </li>
              <li class="flex-box mb-3">
                  <div class="col-md-3 required">New Limits</div>
                  <label class="col-md-9">
                      <ng-select [(ngModel)]="detailsInfo.batchComputeQuota"
                                 [items]="service.batchSkuList"
                                 [multiple]="true"
                                 groupBy="requestType"
                                 name="BatchComputeQuota"
                                 required
                                 #batchComputeQuota="ngModel"
                                 data-test="BatchSkuList">
                          <ng-template ng-option-tmp let-item="item">
                              <ng-container>
                                  <label title="{{ item.label }}" attr.data-test="{{ item.label }}">
                                      {{ item.label }}
                                  </label>
                              </ng-container>
                          </ng-template>
                      </ng-select>
                      <em *ngIf="isTouchedOrDirty(batchComputeQuota) && detailsInfo.batchComputeQuota?.length < 1">Required</em>
                  </label>
              </li>
              <li *ngFor="let quota of detailsInfo.batchComputeQuota" class="flex-box vm-sku-list">
                  <div class="quota-key col-md-3">{{ quota.label }}:</div>
                  <div class="col-md-9">
                      <input class="quota-value"
                             type="number"
                             [(ngModel)]="quota.quota"
                             [ngModelOptions]="{ standalone: true }"
                             placeholder="New limit"
                             attr.data-test="Input-{{ quota.label }}" />
                  </div>
              </li>
              <li>
                  <em *ngIf="!isBatchComputeQuotaValid">New limit is required and must be &gt; 0</em>
              </li>
              <li>
                  <em *ngIf="!isBatchJobQuotaValid">New limit for Batch Active Jobs Schdule must be &gt; 100 and &lt;= 1000</em>
              </li>
          </ul>
      </li>

      <!-- DNS -->
      <li>
          <label>
              <input type="checkbox" [disabled]="serviceDisabled.DNSEntry" [(ngModel)]="detailsInfo.dns.IsSubsriptionQuota" name="DNS" data-test="DNS" />
              DNS Zone and Record Set Quota Per Subscription
          </label>
          <div *ngIf="detailsInfo.dns.IsSubsriptionQuota && !serviceDisabled.DNSEntry" class="ml-2 popover-container">
              <i class="fa fa-info-circle" placement="right" [ngbPopover]="DNSPopover"> </i>
              <ng-template #DNSPopover>
                  Prior to submitting request, please ensure your request is different than the default quota&nbsp;<a target="_blank"
                                                                                                                      href="https://docs.microsoft.com/en-us/azure/azure-resource-manager/management/azure-subscription-service-limits#azure-dns-limits">here</a>
              </ng-template>
          </div>
          <div *ngIf="detailsInfo.dns.IsSubsriptionQuota && !serviceDisabled.DNSEntry">
              <ul class="list-container">
                  <li class="flex-box mb-3">
                      <div class="col-md-3">Requested DNS Zone Quota</div>
                      <div class="col-md-9">
                          <input class="form-control"
                                 [(ngModel)]="detailsInfo.dns.maxZoneQuota"
                                 name="DNSQuota"
                                 title="DNSQuota"
                                 type="number"
                                 #dnsQuota="ngModel"
                                 data-test="DNSQuota" />
                          <ng-container *ngIf="isTouchedOrDirty(dnsQuota)">
                              <em *ngIf="!isDNSQuotaValid">Quota must be greater than 0.</em>
                              <em *ngIf="!isDNSValid">One of the Zone Quota or Record Set Quota must be set.</em>
                          </ng-container>
                      </div>
                  </li>
                  <li class="flex-box mb-3">
                      <div class="col-md-3">Requested DNS Record Set Quota</div>
                      <div class="col-md-9">
                          <input class="form-control"
                                 [(ngModel)]="detailsInfo.dns.maxRecordSetQuota"
                                 name="DNSRecordSetQuota"
                                 title="DNSRecordSetQuota"
                                 type="number"
                                 #dnsRecordSetQuota="ngModel" />
                          <ng-container *ngIf="isTouchedOrDirty(dnsRecordSetQuota)">
                              <em *ngIf="!isDNSRecordSetQuota">Quota must be greater than 0.</em>
                              <em *ngIf="!isDNSValid">One of the Zone Quota or Record Set Quota must be set.</em>
                          </ng-container>
                      </div>
                  </li>
              </ul>
          </div>
      </li>

      <li>
          <label>
              <input type="checkbox" [disabled]="serviceDisabled.DNSEntry" [(ngModel)]="detailsInfo.dns.IsZoneQuota" name="DNS" data-test="DNS" />
              DNS Record Set Quota Per Zone
          </label>
          <div *ngIf="detailsInfo.dns.IsZoneQuota && !serviceDisabled.DNSEntry" class="ml-2 popover-container">
              <i class="fa fa-info-circle" placement="right" [ngbPopover]="DNSPopover"> </i>
              <ng-template #DNSPopover>
                  Prior to submitting request, please ensure your request is different than the default quota&nbsp;<a target="_blank"
                                                                                                                      href="https://docs.microsoft.com/en-us/azure/azure-resource-manager/management/azure-subscription-service-limits#azure-dns-limits">here</a>
              </ng-template>
          </div>
          <div *ngIf="detailsInfo.dns.IsZoneQuota && !serviceDisabled.DNSEntry">
              <ul class="list-container">
                  <li class="flex-box mb-3">
                      <div class="col-md-3">DNS Zone Name</div>
                      <div class="col-md-9">
                          <input class="form-control" [(ngModel)]="detailsInfo.dns.zoneName" name="DNSZoneName" title="DNSZoneName" required />
                          <div class="info-box">
                              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                              <span>If request is only for a specific DNS zone.</span>
                          </div>
                      </div>
                  </li>
                  <li class="flex-box mb-3">
                      <div class="col-md-3">Resource Group Name</div>
                      <div class="col-md-9">
                          <input class="form-control" [(ngModel)]="detailsInfo.dns.resourceGroup" name="DNSResourceGroup" title="DNSResourceGroup" required />
                          <div class="info-box">
                              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                              <span>If request is for all DNS Zones within a Resource Group.</span>
                          </div>
                      </div>
                  </li>
                  <li class="flex-box mb-3">
                      <div class="col-md-3">Requested DNS Record Set Quota</div>
                      <div class="col-md-9">
                          <input class="form-control"
                                 [(ngModel)]="detailsInfo.dns.zoneRecordSetQuota"
                                 name="DNSRecordSetQuota"
                                 title="DNSRecordSetQuota"
                                 type="number"
                                 #dnsRecordSetQuota="ngModel" />
                          <ng-container *ngIf="isTouchedOrDirty(dnsRecordSetQuota)">
                              <em *ngIf="!isDNSRecordSetQuota">Quota must be greater than 0.</em>
                              <em *ngIf="!isDNSValid">One of the Zone Quota or Record Set Quota must be set.</em>
                          </ng-container>
                      </div>
                  </li>
              </ul>
          </div>
      </li>

      <!-- Cosmos DB Access -->
      <li>
          <label>
              <input type="checkbox"
                     [disabled]="serviceDisabled.CosmosDB"
                     name="CosmosDB"
                     [(ngModel)]="detailsInfo.cosmosDB.IsAccess"
                     data-test="IsAccess" />
              Cosmos DB Access
          </label>
      </li>

      <!-- Cosmos DB - AZ Enablement -->
      <li>
          <label>
              <input type="checkbox"
                     [disabled]="serviceDisabled.CosmosDB || serviceDisabled.CosmosDBAz"
                     name="CosmosDBAzEnablement"
                     [(ngModel)]="detailsInfo.cosmosDB.IsAzEnablement"
                     data-test="CosmosDBAzEnablement" />
              Cosmos DB AZ Enablement
          </label>
      </li>

      <!-- Cosmos DB Per Subscription -->
      <li>
          <label>
              <input type="checkbox"
                     [disabled]="serviceDisabled.CosmosDB"
                     name="CosmosDB"
                     [(ngModel)]="detailsInfo.cosmosDB.IsSubscriptionAccounts"
                     data-test="IsSubscriptionAccounts" />
              Cosmos DB Accounts Per Subscription
          </label>
          <ul class="list-container" *ngIf="detailsInfo.cosmosDB.IsSubscriptionAccounts && !serviceDisabled.CosmosDB">
              <li class="flex-box mb-3">
                  <div class="col-md-3 required">Number of Accounts</div>
                  <div class="col-md-9">
                      <input [(ngModel)]="detailsInfo.cosmosDB.SubscriptionAccounts"
                             class="form-control"
                             type="number"
                             title="NumberOfAccounts"
                             required
                             #cosmosDBSubscriptionAccounts="ngModel"
                             data-test="Input" />
                      <em *ngIf="isTouchedOrDirty(cosmosDBSubscriptionAccounts) && !detailsInfo.cosmosDB.SubscriptionAccounts">Required</em>
                  </div>
              </li>
          </ul>
      </li>

      <!-- Cosmos DB Per Account Quota -->
      <li>
          <label>
              <input type="checkbox"
                     [disabled]="serviceDisabled.CosmosDB"
                     name="CosmosDB"
                     title="CosmosDB"
                     [(ngModel)]="detailsInfo.cosmosDB.IsAccountQuota"
                     data-test="IsAccountQuota" />
              Cosmos DB Per Account Quota
          </label>
          <ul class="list-container" *ngIf="detailsInfo.cosmosDB.IsAccountQuota && !serviceDisabled.CosmosDB">
              <li class="flex-box mb-3">
                  <div class="col-md-3 required">Database Account Name</div>
                  <div class="col-md-9">
                      <input class="form-control"
                             [(ngModel)]="detailsInfo.cosmosDB.AccountName"
                             type="text"
                             title="DatabaseAccountName"
                             #cosmosDBAccountName="ngModel"
                             required
                             data-test="CosmosDBAccountName" />
                      <em *ngIf="isTouchedOrDirty(cosmosDBAccountName) && !detailsInfo.cosmosDB.AccountName">Required</em>
                  </div>
              </li>
              <li class="flex-box mb-3">
                  <div class="col-md-3 required">New Limits</div>
                  <label class="col-md-9">
                      <ng-select [(ngModel)]="detailsInfo.cosmosDB.AccountQuotas"
                                 [items]="detailsInfo.cosmosDB.CosmosSkuList"
                                 [multiple]="true"
                                 groupBy="requestType"
                                 name="CosmosAccountQuotas"
                                 required
                                 #CosmosAccountQuotas="ngModel"
                                 data-test="CosmosAccountQuotas">
                          <ng-template ng-option-tmp let-item="item">
                              <ng-container>
                                  <label title="{{ item.label }}" attr.data-test="{{ item.label }}">
                                      {{ item.label }}
                                  </label>
                              </ng-container>
                          </ng-template>
                      </ng-select>
                      <em *ngIf="isTouchedOrDirty(CosmosAccountQuotas) && detailsInfo.cosmosDB.AccountQuotas?.length < 1">Required</em>
                  </label>
              </li>
              <li *ngFor="let quota of detailsInfo.cosmosDB.AccountQuotas" class="flex-box vm-sku-list">
                  <div class="quota-key col-md-3">{{ quota.label }}:</div>
                  <div class="col-md-9">
                      <input class="quota-value"
                             type="number"
                             [(ngModel)]="quota.quota"
                             [ngModelOptions]="{ standalone: true }"
                             placeholder="New limit"
                             attr.data-test="{{ quota.label }}" />
                  </div>
              </li>
              <li>
                  <em *ngIf="!isCosmosDBAccountQuotaLimitsValid">New limit is required and must be greater than 0</em>
              </li>
          </ul>
      </li>

      <!-- App Service Quota -->
      <li>
          <label>
              <input type="checkbox" name="AppService" [disabled]="serviceDisabled.AppService" [(ngModel)]="detailsInfo.requireAppServiceQuota" data-test="RequireAppServiceQuota" />
              APP Service Quota
          </label>
          <ul class="list-container" *ngIf="detailsInfo.requireAppServiceQuota">
              <li class="flex-box mb-3">
                  <div class="col-md-3 required">Offering</div>
                  <label class="col-md-9">
                      <ng-select [(ngModel)]="detailsInfo.appServiceQuota"
                                 [items]="service.appServiceSkuList"
                                 [multiple]="true"
                                 bindLabel="offering"
                                 name="appServiceQuota"
                                 required
                                 #appServiceQuota="ngModel">
                      </ng-select>
                      <em *ngIf="isTouchedOrDirty(appServiceQuota) && !isAppServiceQuotaSelected">Required</em>
                  </label>
              </li>

              <li *ngFor="let appServiceQuotaItem of detailsInfo.appServiceQuota" class="flex-box mt-1">
                  <div class="quota-key col-md-3">{{ appServiceQuotaItem.offering }}</div>
                  <div class="col-md-9">
                      <div>
                          <label class="col-md-3">
                              Windows Instances Limit
                          </label>
                          <input class="ml-3"
                                 type="number"
                                 [(ngModel)]="appServiceQuotaItem.windowsVmQuota"
                                 [ngModelOptions]="{ standalone: true }"
                                 placeholder="New quota limit"
                                 attr.data-test="Input-{{ appServiceQuotaItem.offering }}-WindowVmQuota" />
                      </div>

                      <div>
                          <label class="col-md-3">
                              Linux Instances Limit
                          </label>
                          <input class="ml-3"
                                 type="number"
                                 [(ngModel)]="appServiceQuotaItem.linuxVmQuota"
                                 [ngModelOptions]="{ standalone: true }"
                                 placeholder="New quota limit"
                                 attr.data-test="Input-{{ appServiceQuotaItem.offering }}-LinuxVmQuota" />
                      </div>
                      <em *ngIf="!isAppServiceQuotaValid(appServiceQuotaItem)">WindowsVmQuota or LinuxVmQuota is required and must be greater than 0</em>
                      <hr>
                  </div>
              </li>
          </ul>
      </li>

      <!-- Arm Resource Type -->
      <li>
          <label>
              <input type="checkbox" name="ArmResourceType" [disabled]="serviceDisabled.ArmResourceType" [(ngModel)]="detailsInfo.requireArmResourceType" data-test="RequireArmResourceType" />
              Enable Services by ARM Resource Types
          </label>
          <div class="ml-2 popover-container">
              <i class="fa fa-info-circle" placement="right" [ngbPopover]="ArmResourceType"> </i>
              <ng-template #ArmResourceType>
                  <span>It will be a no-op if the resource type has no restrictions in the region or if your subscription already has access to it.</span>
              </ng-template>
          </div>
          <ul class="list-container" *ngIf="detailsInfo.requireArmResourceType">
              <li class="flex-box mb-3">
                  <div class="col-md-3 required">Resource Types</div>
                  <label class="col-md-9">
                      <ng-select [(ngModel)]="detailsInfo.armResourceTypes"
                                 [items]="service.armResourceTypes"
                                 [multiple]="true"
                                 name="armResourceTypes"
                                 required
                                 #armResourceTypes="ngModel">
                      </ng-select>
                      <em *ngIf="isTouchedOrDirty(armResourceTypes) && !isArmResourceTypeSelected">Required</em>
                  </label>
              </li>
          </ul>
      </li>

      <!--RP Frontload-->
      <li>
          <label>
              <input type="checkbox"
                     [(ngModel)]="detailsInfo.rpFrontload"
                     name="RPFrontload"
                     title="RPFrontload"
                     data-test="RPFrontload"
                     [disabled]="serviceDisabled.RPFrontload" />
              RP Frontload
          </label>
          <ul class="list-container" *ngIf="detailsInfo.rpFrontload">
              <li class="flex-box mb-3">
                  <div class="col-md-3 required">Service Tree ID</div>
                  <div class="col-md-9">
                      <input class="form-control"
                             [(ngModel)]="detailsInfo.serviceTreeId"
                             name="ServiceTreeId"
                             title="ServiceTreeId"
                             type="text"
                             required
                             [pattern]="serviceTreeIdRegExpression"
                             #serviceTreeId="ngModel"
                             data-test="ServiceTreeId" />
                      <ng-container *ngIf="isTouchedOrDirty(serviceTreeId)">
                          <em *ngIf="serviceTreeId.errors?.required">Required</em>
                          <em *ngIf="serviceTreeId.errors?.pattern">Please enter valid Service Tree Id.</em>
                      </ng-container>
                  </div>
              </li>
              <li class="flex-box mb-3">
                  <div class="col-md-3 required">Namespaces</div>
                  <label class="col-md-9">
                      <ng-select [(ngModel)]="detailsInfo.namespaces"
                                 [items]="service.namespaces"
                                 [multiple]="false"
                                 name="Namespaces"
                                 required
                                 #namespaces="ngModel"
                                 data-test="Namespaces">
                      </ng-select>
                      <ng-container *ngIf="isTouchedOrDirty(namespaces)">
                          <em *ngIf="namespaces.errors?.required">Required</em>
                      </ng-container>
                  </label>
              </li>
          </ul>
      </li>

      <!-- Virtual Machine Disk -->
      <li>
          <label>
              <input type="checkbox" [disabled]="serviceDisabled.VmDisks" [(ngModel)]="detailsInfo.requireVmDisks" name="VmDisks" data-test="VmDisks" />
              Virtual Machine Disk
          </label>
          <ul class="list-container" *ngIf="detailsInfo.requireVmDisks && !serviceDisabled.VmDisks">
              <li class="flex-box mb-3">
                  <div class="col-md-3 required">Virtual Machine Disks resource</div>
                  <label class="col-md-9">
                      <ng-select [(ngModel)]="detailsInfo.vmDisksSku"
                                 [items]="service.vmDisks"
                                 name="VmDiskSku"
                                 required
                                 #vmDisksSku="ngModel"
                                 data-test="VmDisksSku">
                          <ng-template ng-option-tmp let-item="item">
                              <ng-container>
                                  <label attr.data-test="{{ item.label }}">
                                      {{ item.label }}
                                  </label>
                              </ng-container>
                          </ng-template>
                      </ng-select>
                      <em *ngIf="isTouchedOrDirty(vmDisksSku) && !isVmDisksSkuValid">Required</em>
                  </label>
              </li>
              <li class="flex-box mb-3">
                  <div class="col-md-3 required">New Limit</div>
                  <div class="col-md-9">
                      <input class="form-control"
                             [(ngModel)]="detailsInfo.vmDisksQuota"
                             name="VmDisksQuota"
                             title="VmDisksQuota"
                             type="number"
                             required
                             #vmDisksQuota="ngModel"
                             data-test="VmDisksQuota" />
                      <em *ngIf="isTouchedOrDirty(vmDisksQuota) && !isVmDisksQuotaValid(detailsInfo.vmDisksSku?.value, detailsInfo.vmDisksQuota)">
                          Quota is required and must be greater than 0 and less than {{ maxVmDiskQuotaLimit(detailsInfo.vmDisksSku?.value) }}
                      </em>
                  </div>
              </li>
          </ul>
      </li>

  </ng-container>
</ul>
<em *ngIf="!isAnyCheckboxSelected">Please select at least one type of requests.</em>
